.EmailVerificationPage {
  text-align: center;
}

.EmailVerificationPage .confirming {
}

.EmailVerificationPage .error {
}

.EmailVerificationPage .confirmed {
  font-size: 20px;
  font-weight: bold;
  color: #831EA3;
}

.EmailVerificationPage .return {
}
