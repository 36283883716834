@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/proximanova-regular-webfont.woff2") format("woff2"),
       url("fonts/proximanova-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/proximanova-semibold-webfont.woff2") format("woff2"),
       url("fonts/proximanova-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/proximanova-bold-webfont.woff2") format("woff2"),
       url("fonts/proximanova-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

html {
  font-family: "Proxima Nova", "Trebuchet MS", sans-serif;
  font-size: 16px;
  line-height: 26px;
}
